export default {
    namespaced: true,

    state: {
        countdownDays: 14,
        endDate: new Date( 2021, 9, 11, 0, 0, 0 )
    },

    getters: {
        countdownDays: state => state.countdownDays,
        endDate: state => state.endDate,
        daysLeft: ( state ) =>
        {
            const today = new Date();
            today.setHours( 12 );
            today.setMinutes( 0 );
            today.setSeconds( 0 );

            const diffInMs = state.endDate.getTime() - today.getTime();
            const diffInDays = Math.ceil( diffInMs / ( 1000 * 3600 * 24 ) );
            return diffInDays;
        },
        daysLeftProgress: ( state, getters ) =>
        {
            return Math.max( 0, Math.min( 1, getters.daysLeft / getters.countdownDays ) );
        }
    },

    mutations: {},

    actions: {}
};
