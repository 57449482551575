<template>
    <div class="landing-view">
        <div class="header-wrapper">
            <dnl-header></dnl-header>
        </div>

        <div class="top-wrapper">
            <dnl-main-visual ref="mainVisual"></dnl-main-visual>

            <button class="button" @click="handleClickout">Word n&uacute; abonnee</button>

            <img class="money-visual" src="/static/img/waaier.png" />
        </div>

        <div class="content-wrapper">
            <dnl-action-bar @ctaClicked="handleClickout"></dnl-action-bar>

            <div class="green-content">
                <v-scrollindicator></v-scrollindicator>
                <h1>Lotto geeft 100 dagen <span class="underline">elke dag</span> <br class="hide-for-small"/>&euro;&nbsp;1.000,- aan een abonnee!</h1>
            </div>

            <div class="usp-content explanation">
                <div class="row">
                    <div class="col-12 m-col-6 l-col-5">
                        <div class="three-steps">
                            <div class="step">
                                <dnl-ball class="bal" ref="ball1" :number="1"></dnl-ball>
                                <p>Word nu abonnee en maak 100 keer kans op &euro;&nbsp;1.000,-</p>
                            </div>
                            <div class="step">
                                <dnl-ball class="bal" ref="ball2" :number="2"></dnl-ball>
                                <p>Maak elke week kans op de Lotto Jackpot van minimaal &euro;&nbsp;2.500.000,-</p>
                            </div>
                            <div class="step">
                                <dnl-ball class="bal" ref="ball3" :number="3"></dnl-ball>
                                <p>Plus exclusieve abonnee&#8209;acties</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 m-col-6 l-col-offset-1">
                        <div class="speel-mee-en-maak-kans">
                            <img src="/static/img/speel_mee_en_maak_kans.png" />

                            <button class="button" @click="handleClickout" >Word&nbsp;n&uacute;&nbsp;abonnee</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import { gsap } from '@/vendor';
    import { v4 as uuidv4 } from 'uuid';

    import DNLHeader from '@/app/components/DNLHeader';
    import DNLMainVisual from '@/app/components/DNLMainVisual';
    import DNLActionBar from '@/app/components/DNLActionBar';
    import ScrollIndicator from '@/app/components/ScrollIndicator';

    import LottoBall from '@/app/components/LottoBall';

    export default {
        name: 'Landing',

        components: {
            'dnl-header': DNLHeader,
            'dnl-main-visual': DNLMainVisual,
            'dnl-action-bar': DNLActionBar,
            'dnl-ball': LottoBall,
            'v-scrollindicator': ScrollIndicator
        },

        methods: {
            handleClickout()
            {
                let originalUrl = new URL( window.location.href );

                let sourceToAffiliate = {
                    'lot-website': null,
                    'lot-search': null,
                    'lot-facebook': null,
                    'lot-email-leads': null,
                    'lot-email-online-players': null,
                    'lot-email-winback': null,
                    'lot-perfect-data': 'PD',
                    'lot-omg': 'OMG',
                    // 'lot-affiliate': 'TD',
                    'lot-vv': 'VV',
                    'lot-vvc': 'VVC',
                    'lot-sjs': 'SJS'
                };

                let sourceToLottoMc = {
                    'lot-website': 'duizendeurowebsite',
                    'lot-search': 'duizendeurosea',
                    'lot-affiliate': 'duizendeurotd',
                    'lot-display': 'duizendeurodisplay',
                    'lot-social': 'duizendeurosocial',
                    'lot-email-leads': 'duizendeuroeml',
                    'lot-email-online-players': 'duizendeuroemo',
                    'lot-email-winback': 'duizendeuroemw',
                    'lot-perfect-data': 'duizendeuropd',
                    'lot-omg': 'duizendeuroomg',
                    'lot-vv': 'duizendeurovv',
                    'lot-vvc': 'duizendeurovvc',
                    'lot-sjs': 'duizendeurosjs'
                };

                let utmSource = originalUrl.searchParams.get( 'utm_source' );

                let affiliateId = null;
                if( sourceToAffiliate[ utmSource ] )
                {
                    affiliateId = sourceToAffiliate[ utmSource ];

                    if( this.code )
                    {
                        affiliateId += this.code.match( /([\d])+/ )[0];
                    }
                    else
                    {
                        affiliateId += uuidv4();
                    }
                }

                let obj = {
                    lotto_mc: sourceToLottoMc[ utmSource ] ? sourceToLottoMc[ utmSource ] : sourceToLottoMc[ 'lot-website' ],
                    promotionId: '2e5eb81e-e091-4f74-b8c0-de1364ff87dc'
                };

                if( affiliateId !== null )
                {
                    obj[ 'affiliateId' ] = affiliateId;
                }

                let fullObj = Object.assign( obj, this.$route.query );

                let baseRedirect = 'https://lotto.nederlandseloterij.nl/automatisch-meespelen/getallen-kiezen';
                let redirectUrl = baseRedirect;

                let i = 0;
                for( let item in fullObj )
                {
                    if( i === 0 )
                    {
                        redirectUrl += '?';
                    }
                    else
                    {
                        redirectUrl += '&';
                    }

                    redirectUrl += item + '=' + fullObj[item];

                    i++;
                }

                window.location.href = redirectUrl;
            },

            getAnimateIn()
            {
                const animation = gsap.timeline({});

                const ballStagger = 0.3;
                animation.add( this.$refs.ball1.spinXTimes( 2, 'y' ), 0.5 - ballStagger );
                animation.add( this.$refs.ball2.spinXTimes( 2, 'y' ), 0.75 - ballStagger );
                animation.add( this.$refs.ball3.spinXTimes( 2, 'y' ), 1 - ballStagger );

                animation.from( '.speel-mee-en-maak-kans > *', { scale: 0, rotation: 45, duration: 0.3, stagger: 0.15 }, 1 );

                return animation;
            }
        }
    };
</script>
