<template>
    <div :class="{'is-sticky': isSticky}" class="green-top">

        <transition name="fade" mode="out-in">
            <dnl-countdown :key="0" class="countdown-elem" v-if="showCountdown && !isSticky && innerWidth > 720"></dnl-countdown>

            <div class="small-bar" :key="1" v-else>
                <span v-if="showCountdown">Nog maar</span>
                <dnl-countdown v-if="showCountdown" :small="true" class="countdown-elem small"></dnl-countdown>
                <span v-if="showCountdown">dagen!</span>

                <a v-if="isSticky && innerWidth <= 720" class="button cta " href="https://lotto.nederlandseloterij.nl/automatisch-meespelen/getallen-kiezen" target="_blank" >Word&nbsp;abonnee</a>
            </div>
        </transition>

        <!-- <div class="small-bar" v-if="isSticky">
            <button class="button cta red" @click="handleBtnClick">Word&nbsp;n&uacute;&nbsp;abonnee</button>
        </div> -->
    </div>
</template>

<style lang="scss" scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .1s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

</style>

<script>
    import { throttle } from '@/vendor';
    import DNLCountdown from '@/app/components/DNLCountdown';

    export default {
        name: 'DNLActionBar',

        components: {
            'dnl-countdown': DNLCountdown
        },

        data()
        {
            return {
                isSticky: false,
                innerWidth: window.innerWidth
            };
        },

        computed: {
            showCountdown()
            {
                return this.$store.getters['staticContent/daysLeft'] <= this.$store.getters['staticContent/countdownDays'];
            }
        },

        mounted()
        {
            this.throttledCheckState = throttle( this.checkState, 100 );
            window.addEventListener( 'scroll', this.throttledCheckState.bind( this ) );

            window.addEventListener( 'resize', this.onResize.bind( this ) );
            this.checkState();
        },

        methods: {
            onResize()
            {
                this.innerWidth = window.innerWidth;
            },

            checkState()
            {
                this.isSticky = this.$el.getBoundingClientRect().y <= 0;
            },

            handleBtnClick()
            {
                this.$emit( 'ctaClicked' );
            }
        }
    };
</script>
