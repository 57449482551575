<template>
    <div class="scroll-indicator">
        <div class="mouse"></div>
        <div class="wheel"></div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { gsap } from '@/vendor';

    export default {
        mounted()
        {
            gsap.set( '.wheel', { xPercent: -50 });
            this.tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });

            this.tl.fromTo( '.wheel', { y: 0, autoAlpha: 1 }, { y: 15, autoAlpha: 0, duration: 1 });
            this.tl.to( '.wheel', { y: 0, duration: 0.01 });
            this.tl.to( '.wheel', { autoAlpha: 1, duration: 0.3 });

            this.onScrollRef = this.onScroll;
            window.addEventListener( 'scroll', this.onScrollRef );

            this.wasKilled = false;
        },

        methods: {
            onScroll()
            {
                if( this.wasKilled )
                {
                    return;
                }

                this.tl.kill();
                gsap.to( '.scroll-indicator', { autoAlpha: 0 });

                gsap.fromTo( '.green-content', { paddingBottom: '6rem' }, { paddingBottom: '1rem', delay: 0.3 });
                // gsap.fromTo( '.green-content h1', { marginTop: '0rem' }, { marginTop: '1.2rem', delay: 0.3 });

                this.wasKilled = true;
            }
        }
    };
</script>
