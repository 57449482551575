<template>
    <div class="lotto-ball" >
        <img class="ball-shadow" src="/static/img/ball-assets/shadow.png" />
        <img class="empty-ball" :src="'/static/img/ball-assets/ball-empty-old.png'" />

        <div class="number-cap">
            <svg class="number" ref="number" version="1.1" viewBox="0 0 175 175" width="100%" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid slice" >
                <g>
                    <text text-anchor="middle" x="50%" y="63%" font-size="40px">{{ ballValue }}</text>
                    <line :x1="ballValue < 10 ? '30%' : '18%'" y1="69%" :x2="ballValue < 10 ? '70%' : '82%'" y2="69%" stroke="black" stroke-width="5" ></line>
                </g>
            </svg>
        </div>

        <img class="glow" src="/static/img/ball-assets/ball-glow.png" />
    </div>
</template>

<style lang="scss" scoped>
    .number{
        will-change:transform;
        height:auto;
    }

    .dropped-overlay{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        padding-bottom:100%;
        height:0;
        background-color:#e6333f;
        border-radius:1000px;
    }

    .empty-ball-red{
        position:absolute;
        top:0;
        left:0;
    }
</style>

<script>
    import { browsercheck } from '@/app/mixins';
    import { gsap } from '@/vendor';

    export default {
        mixins: [ browsercheck ],

        props: {
            'number': {
                type: Number,
                default: 42
            },
            spinTime: {
                type: Number,
                default: 0.2
            },
            spinTimes: {
                type: Number,
                default: 0
            },
            showImmediate: {
                type: Boolean,
                default: false
            }
        },

        data()
        {
            return {
                ballValue: this.number
            };
        },

        watch: {
            ballValue: function( pVal )
            {
                if( pVal > 45 )
                {
                    pVal = pVal % 45;
                    this.ballValue = pVal;
                }
                return pVal;
            }
        },

        mounted()
        {
            if( this.browsercheck.browser === 'safari' && this.browsercheck.browserVersion !== '604' )
            {
                gsap.set( 'svg g', { scale: 0.62, transformOrigin: '50% 50%' });
            }

            this.createTimelines();

            if( this.showImmediate )
            {
                this.spinTl.progress( 1, true );
            }
            else
            {
                this.spinTl.tweenFromTo( 'start', 'spin-start', { ease: 'sine.out' });
            }
        },

        methods: {
            spinXTimes( pAmount, pAxis )
            {
                if( pAxis === 'x' )
                {
                    gsap.set( this.$el.querySelector( '.number-cap' ), { rotationZ: 90 });
                    gsap.set( this.$el.querySelector( '.number-cap > svg > g' ), { transformOrigin: '50% 61%', rotationZ: -90 });

                    // nog een scale naar achter
                }

                // start spin-in
                this.spinTl.tweenFromTo( 'spin-start', 'spinning' ).timeScale( 0.25 );

                this.playTween = gsap.timeline({ delay: 0.5, paused: false });

                // repeat spin voor pAmount
                for( let i = 0; i < pAmount - 1; i++ )
                {
                    // deze kan getimescaled voor debug
                    this.playTween.add( this.spinTl.tweenFromTo( 'spinning', 'spin-ending' ), '+=0' );
                }

                if( pAxis === 'x' )
                {
                    this.playTween.add( () => { this.ballValue--; }); // quick fix zodat roll naar achter hetzelfde nummer blijft
                }

                // deze ook
                // daarna smooth spin-out
                this.playTween.add( this.spinTl.tweenFromTo( 'spin-ending', 'spin-end' ) );

                return this.playTween;
            },

            startSpin()
            {
                this.spinTl.tweenFromTo( 'spin-start', 'spinning' ).timeScale( 0.25 );

                this.playTween = gsap.timeline({ repeat: -1, delay: 0.5 });
                this.playTween.add( this.spinTl.tweenFromTo( 'spinning', 'spin-ending' ), '+=0' );
            },

            stopSpin()
            {
                if( this.playTween )
                {
                    // kill de vorige
                    this.playTween.kill();

                    // animeer uit
                    this.playTween = gsap.timeline();
                    this.playTween.add( this.spinTl.tweenTo( 'spin-end' ) );

                    this.playTween.add( () =>
                    {
                        this.$emit( 'stoppedAt', this.ballValue );
                    }, 0.2 );
                }
            },

            jump()
            {
                let ballElements = this.$el.querySelectorAll( [ '.empty-ball', '.number-cap', '.glow' ] );

                let jumpTl = gsap.timeline();
                jumpTl.to( ballElements, { duration: 0.4, y: -300, ease: 'back.out' }, 0 );
                jumpTl.to( ballElements, { duration: 1, y: 0, ease: 'bounce.out' }, 0.2 );

                return jumpTl;
            },

            createTimelines()
            {
                this.spinTl = gsap.timeline({ paused: true });
                // let repeatTimes = 1; // per 1 sec

                let spinTime = this.spinTime;

                this.spinTl.add( 'start', 0 );
                this.spinTl.add( 'spin-start', 2 );
                this.spinTl.add( 'spinning', 'spin-start+=' + spinTime );
                this.spinTl.add( 'spin-ending', 'spinning+=' + spinTime );
                this.spinTl.add( 'spin-end', 4 );

                let xDist = '30%'; // xoffset van number (mede-verdwijnpunt)
                let negXDist = '-30%'; // xoffset van number (mede-verdwijnpunt)
                let rotY = 90; // hoeveel het nummer af moet buigen

                if( this.browsercheck.browser === 'safari' )
                {
                    xDist = '40%';
                    negXDist = '-40%';
                }

                if( this.browsercheck.browser === 'chrome' )
                {
                    xDist = '60%';
                    negXDist = '-60%';
                    rotY = 42;
                }

                let zOffset = -20; // duwt het nummer iets dieper aan de zijkanten
                let zBase = 5;
                let scaleFactor = 0.9; // maakt het nummer iets kleiner aan de zijkanten

                // smooth in anim
                this.spinTl.fromTo( this.$el.querySelector( '.number' ), {
                    x: xDist,
                    rotationY: rotY,
                    scale: scaleFactor,
                    z: zOffset
                }, {
                    duration: 1,
                    x: '0%',
                    rotationY: 0,
                    scale: 1,
                    z: zBase,
                    ease: 'sine.inout'
                }, 'start' );

                // smooth start spin
                this.spinTl.to( this.$el.querySelector( '.number' ), { duration: spinTime, x: negXDist, rotationY: -rotY, scale: scaleFactor, z: zOffset, ease: 'expo.in' }, 'spinning-=' + spinTime );

                // spinning
                let spin1 = gsap.fromTo( this.$el.querySelector( '.number' ), {
                    x: xDist,
                    scale: scaleFactor,
                    rotationY: rotY,
                    z: zOffset
                }, {
                    duration: spinTime / 2,
                    x: '0%',
                    rotationY: 0,
                    z: zBase,
                    scale: 1.05,
                    ease: 'linear.none'
                });

                let spin2 = gsap.fromTo( this.$el.querySelector( '.number' ), {
                    x: '0%',
                    rotationY: 0,
                    scale: 1.05,
                    z: zBase
                }, {
                    duration: spinTime / 2,
                    x: negXDist,
                    z: zOffset,
                    rotationY: -rotY,
                    scale: scaleFactor,
                    ease: 'linear.none'
                });

                let spinWrapper = gsap.timeline({
                    paused: false,
                    onStart: () =>
                    {
                        // this.ballValue++;
                    }
                });
                spinWrapper.add( spin1 );
                spinWrapper.add( spin2 );
                this.spinTl.add( spinWrapper, 'spinning' );

                // end spin
                this.spinTl.add( () => { /* this.ballValue++; */ }, 'spin-ending+=0.01' );
                this.spinTl.fromTo( this.$el.querySelector( '.number' ), {
                    duration: 1,
                    x: xDist,
                    rotationY: rotY,
                    z: zOffset,
                    scale: scaleFactor
                }, {
                    x: '0%',
                    rotationY: 0,
                    z: zBase,
                    scale: 1,
                    ease: 'expo.out'
                }, 'spin-ending' );
            }
        }
    };
</script>
