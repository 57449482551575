import Vue from 'vue';
import Vuex from 'vuex';

// import entry from './modules/entry';
import staticContent from './modules/staticContent';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        staticContent
        // entry
    },

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {

    }
});
