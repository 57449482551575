<template>
    <div class="visual-wrapper">
        <img class="visual" src="/static/img/actie-visual.png" />
        <img class="arrow" src="/static/img/pijltje.png" />
    </div>
</template>

<style lang="scss" scoped>
    .visual-wrapper{
        img{
            max-height:100%;
        }
    }
</style>

<script>
    import { gsap } from '@/vendor';

    export default {
        name: 'DNLMainVisual',

        data()
        {
            return {
                numFlaps: 25
            };
        },

        mounted()
        {
        },

        methods: {
            getAnimateIn()
            {
                const tl = gsap.timeline({});

                // tl.set( this.$el, { height: '100vh', y: -40 });
                // tl.to( this.$el, { height: '60vh' }, 1.5 );

                return tl;
            }
        }
    };
</script>
