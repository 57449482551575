<template>
    <div :class="{ 'countdown': true, 'small': small }">
        <p>Je hebt nog</p>
        <p class="daysleft">{{ daysLeft }}</p>
        <p>dagen</p>

        <dnl-progress-circle :initialProgress="progress" class="line"></dnl-progress-circle>
    </div>
</template>

<script>
    import DNLProgressCircle from '@/app/components/DNLProgressCircle';

    export default {
        name: 'DNLCountdown',

        components: {
            'dnl-progress-circle': DNLProgressCircle
        },

        props: {
            small: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            daysLeft()
            {
                return this.$store.getters['staticContent/daysLeft'];
            },

            progress()
            {
                return this.$store.getters['staticContent/daysLeftProgress'];
            }
        }
    };
</script>
