<template>
    <div class="main-layout">
        <slot></slot>

        <footer>
            <div class="content-wrapper">
                <img src="/static/img/logos/footer.png" />
                <img class="big" src="/static/img/logos/footer-big.png" />
                <p class="disclaimer small">Voor het organiseren van Lotto is aan Lotto B.V. vergunning verleend door de Kansspelautoriteit, kenmerk 10510 d.d. 23 december 2016. Trekking Laan van Hoornwijck 55 te Rijswijk.<br class="hide-for-small" />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a @click="trackAv" :href="'/static/pdf/actievoorwaarden.pdf'" target="_blank">actievoorwaarden</a>. | <a @click="trackPrivacyClickout" href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse Loterij</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>
    .main-layout{
        position: relative;
        height:100%;
    }
</style>

<script>
    import { tracking } from '@/app/mixins';

    export default {
        mixins: [ tracking ],

        methods: {
            trackAv()
            {
                this.trackEvent( 'Klik op link - Actievoorwaarden', 'Link tracking', window.location.origin + '/static/pdf/actievoorwaarden.pdf' );
            },

            trackPrivacyClickout()
            {
                this.trackEvent( 'Klik op link - Privacy Statement', 'Link tracking', 'Nieuw venster - Privacy Statement - https://www.nederlandseloterij.nl/privacy' );
            }
        }
    };
</script>
