import Vue from 'vue';
import Router from 'vue-router';

import Landing from '@/app/pages/Landing';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    scrollBehavior( to, from, savedPosition )
    {
        return { x: 0, y: 0 };
    },
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: Landing
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
