<template>
    <nav ref="nav" class="dnl-header">
        <dnl-logo></dnl-logo>
    </nav>
</template>

<style lang="scss" scoped>
    .visual-wrapper{
        img{
            max-height:100%;
        }
    }
</style>

<script>
    import { gsap, throttle } from '@/vendor';
    import DNLLogo from '@/app/components/DNLLogo';

    export default {
        name: 'DNLHeader',

        components: {
            'dnl-logo': DNLLogo
            // 'dnl-countdown': DNLCountdown
        },

        data()
        {
            return {
                collapsed: false
            };
        },

        computed: {
            daysLeft()
            {
                return 3;
            }
        },

        mounted()
        {
            this.animation = gsap.timeline({ paused: true });
            this.animation.to( this.$refs[ 'nav' ], { height: 100, duration: 0.2 }, 0 );
            this.animation.to( '.main-layout', { paddingTop: '100px', duration: 0.2 }, 0 );

            this.throttledCheckState = throttle( this.checkState.bind( this ), 200 );
            // window.addEventListener( 'scroll', this.throttledCheckState );
        },

        beforeDestroy()
        {
            // window.removeEventListener( 'scroll', this.throttledCheckState );
        },

        methods: {
            setCollapsed( yes )
            {
                if( this.collapsed === yes )
                {
                    return;
                }

                if( yes )
                {
                    this.collapsed = true;
                    this.animation.play();
                }
                else
                {
                    this.collapsed = false;
                    this.animation.reverse();
                }
            },

            checkState()
            {
                if( this.collapsed )
                {
                    if( window.pageYOffset < 10 )
                    {
                        this.setCollapsed( false );
                    }
                }
                else
                {
                    this.setCollapsed( window.pageYOffset > 30 );
                }
            }
        }
    };
</script>
