<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle
            cx="50"
            cy="50"
            r="49"
            stroke-width="3"
            stroke="#ffdd00"
            fill="none"
            :stroke-dasharray="dashLength + ' 10000'"
            style="transform-origin: 50% 50%; transform: rotate(-90deg)" />
    </svg>
</template>

<script>
    const MAX_LINE_LENGTH = Math.PI * 49 * 2;

    export default {
        name: 'DNLProgressCircle',

        props: {
            initialProgress: {
                type: Number,
                default: 1
            }
        },

        data()
        {
            return {
                currentProgress: this.initialProgress
            };
        },

        computed: {
            dashLength()
            {
                return MAX_LINE_LENGTH * this.currentProgress;
            }
        }
    };
</script>
